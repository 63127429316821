import { faArrowRightFromBracket, faComments, faQuestionCircle, faTasks, faUpload, faVideoCamera, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState, useRef, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import SimpleMDE from "react-simplemde-editor";
import Breadcrumb from "../../../components/global/Breadcrumb/Breadcrumb";
import Modal from "../../../components/global/Modal/Modal";
import SidebarCollapse from "../../../components/global/SidebarCollapse/SidebarCollapse";
import CardDiscussion from "../../../components/learn/discussion/CardDiscussion";
import SectionHeader from "../../../components/learn/Section/SectionHeader/SectionHeader";
import SectionVideo from "../../../components/learn/Section/SectionVideo/SectionVideo";
import { getCourse } from "../../../features/courses/courseSlice";
import { getLesson, getMarkedLesson } from "../../../features/lessons/lessonSlice";

// React Markdown
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm';
import rehypeRaw from "rehype-raw";
import rehypeSanitize from 'rehype-sanitize';
import { getDiscussionByLesson, postDiscussion, resetDiscussionState } from "../../../features/discussion/discussionSlice";
import axios from "axios";
import Footer from "../../../components/global/Footer/Footer";
import Skeleton from "react-loading-skeleton";
import CardNote from "../../../components/learn/Card/CardNote/CardNote";
import { checkCompletedCourse } from "../../../features/courses/courseCheckSlice";
import { deleteNote, getNote, getNotes, postNote, updateNote } from "../../../features/lessons/noteSlice";
import SkeletonCardNote from "../../../components/skeletons/SkeletonCardNote";
import { useDetectAdBlock } from "adblock-detect-react";

// Sweetalert
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { CodeBlock } from "../../../components/global/CodeBlock";

// Mixpanel
import mixpanel from "mixpanel-browser";
import ButtonBackToTop from "../../../components/global/Buttons/ButtonBackToTop/ButtonBackToTop";
import Quiz from "../../../components/learn/Quiz/Quiz";
import { userToken } from "../../../utils/config";

mixpanel.init("608746391f30c018d759b8c2c1ecb097")

const CourseLesson = () => {
    const MySwal = withReactContent(Swal);
    const adBlockDetected = useDetectAdBlock();

    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [disableMarkingButton, setDisableMarkingButton] = useState(false);
    const [vdoCipherSource, setVdoCipherSource] = useState('');
    const [alertShown, setAlertShown] = useState(false);

    // Discussion
    const [threadSubject, setThreadSubject] = useState('');
    const [threadContent, setThreadContent] = useState('');

    // notes
    const [isEdit, setIsEdit] = useState(false);
    const [noteId, setNoteId] = useState('');
    const [noteTitle, setNoteTitle] = useState('');
    const [noteDescription, setNoteDescription] = useState('');
    const [noteMinute, setNoteMinute] = useState('');


    // State from Redux
    const { theme } = useSelector((state) => (state.theme));
    const { course } = useSelector((state) => ({ ...state.courses }));
    const { completedCourse } = useSelector((state) => ({ ...state.completedCourse }));
    const { lesson, markedLessons, loadingLesson } = useSelector((state) => ({ ...state.lesson }));
    const { lessonDiscussions, loadingDiscussion, postDiscussionResult } = useSelector((state) => ({ ...state.discussion }));
    const { notes, loadingNotes } = useSelector((state) => ({ ...state.note }));

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { slug, lessonId } = useParams();
    const containerRef = useRef();

    // Set sidebar default to closed in mobile device
    useEffect(() => {
        const width = window.innerWidth;
        if (width < 800) setSidebarOpen(false);
        else setSidebarOpen(true);
    }, []);

    // Get Data
    useEffect(() => {
        if (slug) {
            dispatch(getCourse(slug));
        };
        if (course?.course?.id) {
            dispatch(getMarkedLesson(course?.course?.id));
            dispatch(checkCompletedCourse(course?.course?.id));
        };
        if (lessonId) {
            dispatch(getLesson(parseInt(lessonId)));
            dispatch(getDiscussionByLesson(lessonId));
            dispatch(getNotes(parseInt(lessonId)));
        }
    }, [dispatch, slug, lessonId, course?.course?.id]);

    // Tracking mixpanel
    useEffect(() => {
        if (lesson?.lesson_title !== undefined) {
            if (lesson?.type === 'quiz') {
                mixpanel.track(`Buka Quiz : ${course?.course?.title} - ${lesson?.lesson_title}`, { 'source': 'Dashboard V2' })
            } else {
                mixpanel.track(`Buka Lesson : ${course?.course?.title} - ${lesson?.lesson_title}`, { 'source': 'Dashboard V2' })
            }
        }
    }, [course?.course?.title, lesson?.lesson_title, lesson?.type]);

    // Get OTP VDOCipher
    useEffect(() => {
        try {
            const getOTP = async () => {
                if (lesson?.vdocipher_id !== undefined) {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/cipher/otp/${lesson?.vdocipher_id}`, {
                        headers: {
                            Authorization: userToken
                        }
                    });

                    if (response && lesson?.id.toString() === lessonId) {
                        setVdoCipherSource(`https://player.vdocipher.com/v2/?otp=${response.data.otp}&playbackInfo=${response.data.playbackInfo}`);
                    };
                };
            };

            if (lesson?.player === 'vdocipher') {
                getOTP();
            };
        } catch (error) {
            throw new Error(error);
        }
    }, [lesson, lessonId]);

    // Disable marking button
    useEffect(() => {
        const newMarkedLessons = [];
        markedLessons.forEach(markedLesson => {
            newMarkedLessons.push(parseInt(markedLesson.lesson_id));
        });
        if (newMarkedLessons?.includes(parseInt(lessonId))) {
            setDisableMarkingButton(true);
        } else {
            setDisableMarkingButton(false);
        }
    }, [lessonId, markedLessons]);

    // Alert Check
    useEffect(() => {
        if (Object.entries(postDiscussionResult).length > 0) {
            MySwal.fire({
                title: <strong>{postDiscussionResult?.status === 'success' ? 'Berhasil' : 'Gagal'}</strong>,
                text: postDiscussionResult?.status === 'success' ? 'Diskusi berhasil ditambahkan' : 'Diskusi gagal ditambahkan',
                icon: postDiscussionResult?.status === 'success' ? 'success' : 'error',
                showConfirmButton: false,
                timer: 2000,
            }).then(dispatch(resetDiscussionState()));
        }
    }, [postDiscussionResult, MySwal, dispatch]);

    // Scroll To top
    const scrollToTop = () => {
        const mainContainer = document.getElementById('main-container');
        mainContainer.scrollIntoView({ behavior: 'smooth' });
    };

    // handle post mark lesson
    const handleMarkLesson = async () => {
        mixpanel.track(`Saya Sudah Paham : ${course?.course?.title} - ${lesson?.lesson_title}`, { 'source': 'Dashboard V2' })

        let data = {
            'course_id': course?.course?.id,
            'lesson_id': parseInt(lessonId)
        };

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/lesson/mark`, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: userToken
            }
        })

        if (response.data.status === "success") {
            dispatch(getMarkedLesson(course?.course?.id));

            if (lesson?.next !== null) {
                MySwal.fire({
                    title: <strong>{response.data.status === "success" ? "Mantap!" : "Oops..."}</strong>,
                    text: response.data.status === 'success' ? 'Pelajaran ini berhasil kamu selesaikan. Lanjut ke materi selanjutnya ya.' : 'Sepertinya terjadi kesalahan, coba lagi nanti.',
                    icon: response.data.status === 'success' ? 'success' : 'error',
                    confirmButtonText: 'Oke!',
                    confirmButtonColor: '#14a7a0'
                }).then(
                    lesson?.next !== null && navigate(`/learn/courses/${slug}/lessons/${lesson?.next}`)
                );

                containerRef.current.scrollIntoView();
            };
        } else {
            console.log(response.data);
        };
    };

    // Handling complete course marking
    const handleCompleteCourse = useCallback(async () => {
        let data = {
            'course_id': course?.course?.id,
        };

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/course/complete`, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: userToken
            }
        })

        if (response.data.status === "success" && course?.course?.enable_certificate !== 0) {
            navigate(`/learn/courses/${slug}/claim-certificate`);
        } else {
            navigate(`/learn/courses/detail/${slug}`);
        }
    }, [course, slug, navigate]);

    useEffect(() => {
        setAlertShown(false);
    }, [location]);

    // Check if all lessons are already marked as completed and Show Pop up alert when all the lessons are marked to complete the course
    useEffect(() => {
        const coursePrecentage = Math.floor((course?.total_marked / course?.total_module) * 100);

        if (lesson?.next === null && disableMarkingButton && completedCourse?.status !== 'success' && coursePrecentage > 99 && lesson?.type !== 'quiz' && !alertShown) {
            MySwal.fire({
                title: <strong>Selamat!</strong>,
                text: `Kamu sudah menyelesaikan kelas ini ${course?.course?.enable_certificate !== 0 ? ', klik tombol Selesai untuk claim sertifikatmu!' : ''}`,
                icon: 'success',
                confirmButtonText: 'Selesai',
                confirmButtonColor: '#14a7a0',
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                if (result.isConfirmed) {
                    handleCompleteCourse();
                };
            });
            setAlertShown(true);
        };
    }, [MySwal, completedCourse?.status, course?.total_marked, course?.total_module, course?.course?.enable_certificate, disableMarkingButton, handleCompleteCourse, lesson?.next, lesson?.type, alertShown]);

    // Handle onChange Thread Content
    const onChangeThreadContent = (value) => {
        setThreadContent(value);
    };

    // handle submit discussion
    const handleSubmitDiscussion = async (e) => {
        e.preventDefault();
        dispatch(postDiscussion({
            courseId: course?.course?.id,
            lessonId: parseInt(lessonId),
            threadSubject: threadSubject,
            threadContent: threadContent
        })).then(
            setThreadSubject(''),
            setThreadContent('')
        );
    };

    // handle Add Note
    const onAddNote = async (e) => {
        e.preventDefault();
        dispatch(postNote({
            lessonId: parseInt(lessonId),
            noteMinute: noteMinute,
            noteTitle: noteTitle,
            noteDescription: noteDescription
        })).then((result) => {
            MySwal.fire({
                title: <strong>{result.payload?.status === 'success' ? 'Berhasil' : 'Gagal'}</strong>,
                text: result.payload?.status === 'success' ? 'Catatan berhasil ditambahkan' : 'Catatan gagal ditambahkan',
                icon: result.payload?.status === 'success' ? 'success' : 'error',
                showConfirmButton: false,
                timer: 2000
            });

            setNoteMinute('');
            setNoteTitle('');
            setNoteDescription('');
        });
    };

    // handle Edit Note
    const onEditNote = (id) => {
        setIsEdit(true);
        dispatch(getNote(id)).then((result) => {
            setNoteId(result.payload.id);
            setNoteTitle(result.payload.title);
            setNoteDescription(result.payload.description);
            setNoteMinute(result.payload.minute);
        });
    };

    // handle Update Note
    const onUpdateNote = async (e) => {
        e.preventDefault();
        dispatch(updateNote({
            id: noteId,
            lessonId: parseInt(lessonId),
            noteMinute: noteMinute,
            noteTitle: noteTitle,
            noteDescription: noteDescription
        })).then((result) => {
            MySwal.fire({
                title: <strong>{result.payload?.status === 'success' ? 'Berhasil' : 'Gagal'}</strong>,
                text: result.payload?.status === 'success' ? 'Catatan berhasil diupdate' : 'Catatan gagal diupdate',
                icon: result.payload?.status === 'success' ? 'success' : 'error',
                showConfirmButton: false,
                timer: 2000
            });
        });
    };

    const onDeleteNote = (noteId, lessonId) => {
        dispatch(deleteNote({ id: noteId, lessonId: lessonId })).then((result) => {
            MySwal.fire({
                title: <strong>{result.payload?.status === 'success' ? 'Berhasil' : 'Gagal'}</strong>,
                text: result.payload?.status === 'success' ? 'Catatan berhasil dihapus' : 'Catatan gagal dihapus',
                icon: result.payload?.status === 'success' ? 'success' : 'error',
                showConfirmButton: false,
                timer: 2000
            });
        });
    };

    const handleResetNoteForm = () => {
        setIsEdit(false);
        setNoteMinute('');
        setNoteTitle('');
        setNoteDescription('');
    };

    const handleClickAskButton = () => {
        const discussionButton = document.querySelector(".btn-discussion");
        discussionButton.click();
        discussionButton.scrollIntoView();
    };

    // Check if lesson is contain text only
    const [showVideo, setShowVideo] = useState(false)
    useEffect(() => {
        if (lesson.player === 'youtube' && lesson.youtube_id) setShowVideo(true)
        else if (lesson.player === 'bunnystream' && lesson.bunnystream_id) setShowVideo(true)
        else if (lesson.player === 'vdocipher' && lesson.vdocipher_id) setShowVideo(true)
        else setShowVideo(false)
    }, [lesson]);

    // Show Forbidden Page to unauthorized user
    useEffect(() => {
        if (!loadingLesson && lesson?.is_authorized === 0 && !alertShown) {
            MySwal.fire({
                icon: 'warning',
                title: 'Akses Dilarang',
                text: 'Maaf kamu tidak diizinkan untuk mengakses materi ini. Silahkan beli kelas ini terlebih dahulu untuk dapat mengaksesnya.',
                confirmButtonColor: '#14a7a0',
                confirmButtonText: 'Beli Kelas',
                allowOutsideClick: false,
                allowEscapeKey: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(`/learn/courses/detail/${course?.course?.slug}`);
                };
            });
            setAlertShown(true);
        };
    }, [loadingLesson, lesson, MySwal, navigate, course?.course?.slug, alertShown]);

    return (
        <>
            <Helmet>
                <title>{lesson?.lesson_title}</title>
            </Helmet>
            <main id="main">
                <SidebarCollapse
                    isOpen={sidebarOpen}
                    onClickToggle={() => setSidebarOpen(!sidebarOpen)}
                    courseSlug={slug}
                    examData={course?.exam}
                    markedLessons={markedLessons}
                    lessons={course?.lessons}
                    activeTopicId={lesson?.topic_id}
                    courseIsComplete={completedCourse}
                    enableCertificate={course?.course?.enable_certificate}
                    onClickItem={() => containerRef.current.scrollIntoView()}
                />

                <div className={`content ${sidebarOpen && 'open'} ${theme === 'light' && 'bg-light'} ${theme === 'dark' && 'bg-dark text-white'} overflow-auto`}>
                    <div className="container p-4" id="main-container" ref={containerRef}>
                        <div className="row justify-content-between">
                            <div className="col-auto">
                                <Breadcrumb>
                                    <li className="breadcrumb-item">
                                        <Link className="link" to="/learn/courses">My Learning</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link className="link" to={`/learn/courses/detail/${course.course?.slug}`}>{course?.course?.title.slice(0, 15) + '...'}</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        {document.title}
                                    </li>
                                </Breadcrumb>
                            </div>
                            {lesson?.next !== null && (
                                <div className="col-auto">
                                    <Link className="link text-primary" to={`/learn/courses/${slug}/lessons/${lesson?.id === parseInt(lessonId) && lesson?.next}`}>Selanjutnya</Link>
                                </div>
                            )}
                        </div>

                        <SectionHeader
                            type="lesson"
                            loading={loadingLesson}
                            lessonData={lesson}
                        />

                        <section className="section my-3">
                            <ul className="nav nav-pills border-0 text-nowrap overflow-auto flex-nowrap" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active px-3 fw-bolder" id="pills-module-tab" data-bs-toggle="pill" data-bs-target="#pills-module" type="button" role="tab" aria-controls="pills-module" aria-selected="true">
                                        <FontAwesomeIcon className="me-2" icon={lesson?.type === 'quiz' ? faTasks : faVideoCamera} />
                                        {lesson?.type === 'quiz' ? "Quiz" : "Materi"}
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link px-3 btn-discussion fw-bolder" id="pills-discussion-tab" data-bs-toggle="pill" data-bs-target="#pills-disscussion" type="button" role="tab" aria-controls="pills-disscussion" aria-selected="false">
                                        <FontAwesomeIcon className="me-2" icon={faQuestionCircle} />
                                        Questions
                                    </button>
                                </li>
                                {lesson?.type !== 'quiz' && (
                                    <li className="nav-item ms-auto" role="presentation">
                                        <button className="nav-link px-3 fw-bolder" id="pills-notes-tab" data-bs-toggle="pill" data-bs-target="#pills-notes" type="button" role="tab" aria-controls="pills-notes" aria-selected="false">
                                            Notes
                                        </button>
                                    </li>
                                )}
                            </ul>
                            <div className="tab-content my-3" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-module" role="tabpanel" aria-labelledby="pills-module-tab">
                                    {adBlockDetected && (
                                        <div className="alert alert-warning mt-4" role="alert">
                                            <strong><FontAwesomeIcon icon={faWarning} /> Perhatian:</strong> Sepertinya kamu terdeteksi menggunakan AdBlock, tolong matikan agar video tidak terganggu dan kamu dapat belajar dengan lebih nyaman.
                                        </div>
                                    )}

                                    <div className={`card rounded-3 ${theme === 'dark' && 'bg-soft-dark'} mb-3`}>
                                        <div className="card-img-top">
                                            {showVideo && lesson?.type !== 'quiz' ? (
                                                <SectionVideo
                                                    loading={loadingLesson}
                                                    player={lesson?.player}
                                                    youtubeId={lesson?.youtube_id}
                                                    bunnystreamId={lesson?.bunnystream_id}
                                                    diuploadId={lesson?.diupload_id}
                                                    vdoSource={vdoCipherSource}
                                                />
                                            ) : null}
                                        </div>
                                        <div className="card-body p-4 p-lg-5">
                                            {loadingLesson && (
                                                <>
                                                    <Skeleton count={1} className="bg-white" width="70%" />
                                                    <Skeleton count={3} className="bg-white" width="100%" />
                                                </>
                                            )}
                                            {!loadingLesson && lesson?.theory === "" && (
                                                <p className={`${theme === 'dark' ? 'text-white' : 'text-muted'} text-center my-auto`}>Tidak ada materi teks</p>
                                            )}
                                            {!loadingLesson && lesson?.type !== "quiz" ? (
                                                <div className={theme === 'dark' ? "text-white" : "text-muted"} id="customMarkdown">
                                                    <ReactMarkdown
                                                        className="markdown"
                                                        remarkPlugins={[remarkGfm]}
                                                        rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                                        components={CodeBlock}
                                                    >
                                                        {lesson?.theory}
                                                    </ReactMarkdown>
                                                </div>
                                            ) : (
                                                <Quiz data={lesson} type="quiz" />
                                            )}
                                        </div>
                                    </div>
                                    {!loadingLesson && lesson?.is_authorized === 1 && (
                                        <div className={`card ${theme === 'dark' && 'bg-soft-dark'}`}>
                                            <div className="card-body">
                                                <div className="row justify-content-between">
                                                    <div className="col-lg-auto my-3 my-lg-auto">
                                                        <h5 className={`${theme === 'dark' ? 'text-white' : 'text-muted'} m-0`}>Apakah sudah paham?</h5>
                                                    </div>
                                                    <div className="col-lg-auto my-lg-auto">
                                                        <div className="d-grid d-lg-flex gap-2">
                                                            <button onClick={handleClickAskButton} type="button" className="btn btn-secondary btn-rounded"><FontAwesomeIcon icon={faComments} /> Tanyakan di Forum</button>
                                                            {lesson?.type !== 'quiz' && (
                                                                <span onClick={handleMarkLesson} className="btn btn-primary btn-rounded" disabled={disableMarkingButton}><FontAwesomeIcon icon={faArrowRightFromBracket} /> Ya, Saya Sudah Paham</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="tab-pane fade" id="pills-disscussion" role="tabpanel" aria-labelledby="pills-disscussion-tab">
                                    <section className="my-3">
                                        <div className="card bg-transparent border-0">
                                            <div className={`card-header ${theme === 'dark' ? 'bg-soft-dark' : 'bg-white'}`}>
                                                <h2 className={`section-title ${theme === 'dark' && 'text-white'} h3 mb-0`}>Questions</h2>
                                            </div>
                                            <div className="card-body px-0">
                                                <ul className="nav nav-pills border-0 text-nowrap overflow-auto flex-nowrap" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link active px-3" id="pills-all-tab" data-bs-toggle="pill" data-bs-target="#pills-all" type="button" role="tab" aria-controls="pills-all" aria-selected="true">All</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link px-3" id="pills-answered-tab" data-bs-toggle="pill" data-bs-target="#pills-answered" type="button" role="tab" aria-controls="pills-answered" aria-selected="false">Answered</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link px-3" id="pills-unanswered-tab" data-bs-toggle="pill" data-bs-target="#pills-unanswered" type="button" role="tab" aria-controls="pills-unanswered" aria-selected="false">Unanswered</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link px-3" id="pills-solved-tab" data-bs-toggle="pill" data-bs-target="#pills-solved" type="button" role="tab" aria-controls="pills-solved" aria-selected="false">Solved</button>
                                                    </li>
                                                </ul>

                                                <div className="tab-content my-3" id="pills-tabContent">
                                                    <div className="tab-pane fade show active" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab">
                                                        {!loadingDiscussion && lessonDiscussions?.length < 1 && (
                                                            <p className="text-center text-muted my-5">Tidak ada pertanyaan</p>
                                                        )}
                                                        {!loadingDiscussion && lessonDiscussions?.map((discussion, index) => {
                                                            return (
                                                                <div className="mb-3" key={index}>
                                                                    <a className="link" href={`/learn/discussions/thread/${discussion.slug}`} target="_blank" rel="noopener noreferrer">
                                                                        <CardDiscussion
                                                                            avatar={discussion.avatar}
                                                                            name={discussion.name}
                                                                            subject={discussion.subject}
                                                                            mark={discussion.mark}
                                                                            date={discussion.date}
                                                                            courseTitle={discussion.course_title}
                                                                            courseSlug={discussion.course_slug}
                                                                            lessonTitle={discussion.lesson_title}
                                                                            lessonId={discussion.lesson_id}
                                                                            totalAnswer={discussion.total_answer}
                                                                            tags={discussion.tags}
                                                                            theme={theme}
                                                                        />
                                                                    </a>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>

                                                    <div className="tab-pane fade" id="pills-answered" role="tabpanel" aria-labelledby="pills-answered-tab">
                                                        {!loadingDiscussion && lessonDiscussions?.filter(discussion => discussion.total_answer > 0 && discussion.mark !== 'solved').length < 1 && (
                                                            <p className="text-center text-muted my-5">Tidak ada diskusi</p>
                                                        )}
                                                        {!loadingDiscussion && lessonDiscussions?.filter(discussion => discussion.total_answer > 0 && discussion.mark !== 'solved').map((discussion, index) => {
                                                            return (
                                                                <div className="mb-3" key={index}>
                                                                    <a className="link" href={`/learn/discussions/thread/${discussion.slug}`} target="_blank" rel="noopener noreferrer">
                                                                        <CardDiscussion
                                                                            avatar={discussion.avatar}
                                                                            name={discussion.name}
                                                                            subject={discussion.subject}
                                                                            mark={discussion.mark}
                                                                            date={discussion.date}
                                                                            courseTitle={discussion.course_title}
                                                                            courseSlug={discussion.course_slug}
                                                                            lessonTitle={discussion.lesson_title}
                                                                            lessonId={discussion.lesson_id}
                                                                            totalAnswer={discussion.total_answer}
                                                                            tags={discussion.tags}
                                                                            theme={theme}
                                                                        />
                                                                    </a>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>

                                                    <div className="tab-pane fade" id="pills-unanswered" role="tabpanel" aria-labelledby="pills-unanswered-tab">
                                                        {!loadingDiscussion && lessonDiscussions?.filter(discussion => discussion.total_answer < 1 && discussion.mark !== 'solved').length < 1 && (
                                                            <p className="text-center text-muted my-5">Tidak ada diskusi</p>
                                                        )}
                                                        {!loadingDiscussion && lessonDiscussions?.filter(discussion => discussion.total_answer < 1 && discussion.mark !== 'solved').map((discussion, index) => {
                                                            return (
                                                                <div className="mb-3" key={index}>
                                                                    <a className="link" href={`/learn/discussions/thread/${discussion.slug}`} target="_blank" rel="noopener noreferrer">
                                                                        <CardDiscussion
                                                                            avatar={discussion.avatar}
                                                                            name={discussion.name}
                                                                            subject={discussion.subject}
                                                                            mark={discussion.mark}
                                                                            date={discussion.date}
                                                                            courseTitle={discussion.course_title}
                                                                            courseSlug={discussion.course_slug}
                                                                            lessonTitle={discussion.lesson_title}
                                                                            lessonId={discussion.lesson_id}
                                                                            totalAnswer={discussion.total_answer}
                                                                            tags={discussion.tags}
                                                                            theme={theme}
                                                                        />
                                                                    </a>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>

                                                    <div className="tab-pane fade" id="pills-solved" role="tabpanel" aria-labelledby="pills-solved-tab">
                                                        {!loadingDiscussion && lessonDiscussions?.filter(discussion => discussion.mark === 'solved').length < 1 && (
                                                            <p className="text-center text-muted my-5">Tidak ada diskusi</p>
                                                        )}
                                                        {!loadingDiscussion && lessonDiscussions?.filter(discussion => discussion.mark === 'solved').map((discussion, index) => {
                                                            return (
                                                                <div className="mb-3" key={index}>
                                                                    <a className="link" href={`/learn/discussions/thread/${discussion.slug}`} target="_blank" rel="noopener noreferrer">
                                                                        <CardDiscussion
                                                                            avatar={discussion.avatar}
                                                                            name={discussion.name}
                                                                            subject={discussion.subject}
                                                                            mark={discussion.mark}
                                                                            date={discussion.date}
                                                                            courseTitle={discussion.course_title}
                                                                            courseSlug={discussion.course_slug}
                                                                            lessonTitle={discussion.lesson_title}
                                                                            lessonId={discussion.lesson_id}
                                                                            totalAnswer={discussion.total_answer}
                                                                            tags={discussion.tags}
                                                                            theme={theme}
                                                                        />
                                                                    </a>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer bg-transparent border-0 text-center">
                                                <h5 className="text-muted my-3">Belum menemukan jawaban?</h5>
                                                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#createDiscussionModal">
                                                    Buat Pertanyaan Baru
                                                </button>
                                            </div>
                                        </div>
                                    </section>
                                </div>

                                <div className="tab-pane fade" id="pills-notes" role="tabpanel" aria-labelledby="pills-notes-tab">
                                    <div className="row justify-content-between mb-3">
                                        <div className="col-auto">
                                            <h3 className={`section-title ${theme === 'dark' && 'text-white'}`}>Notes</h3>
                                        </div>
                                        <div className="col-auto">
                                            <button onClick={handleResetNoteForm} type="button" className="btn btn-primary btn-rounded" data-bs-toggle="modal" data-bs-target="#notesModal">
                                                Add Note
                                            </button>
                                        </div>
                                    </div>
                                    {loadingNotes && [1, 2, 3].map(item => {
                                        return (
                                            <div className="row mb-3" key={item}>
                                                <SkeletonCardNote />
                                            </div>
                                        )
                                    })}
                                    {!loadingNotes && !notes && (
                                        <p className="text-center text-muted my-5">Tidak ada catatan</p>
                                    )}
                                    {!loadingNotes && notes?.map((note, index) => {
                                        return (
                                            <div className="row mb-3" key={index}>
                                                <CardNote
                                                    index={index}
                                                    onEdit={() => onEditNote(note.id)}
                                                    onDelete={() => onDeleteNote(note.id, note.lesson_id)}
                                                    courseTitle={course?.course?.title}
                                                    lessonTitle={lesson?.lesson_title}
                                                    noteMinute={note.minute}
                                                    noteTitle={note.title}
                                                    noteDescription={note.description}
                                                    theme={theme}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </section>
                        <ButtonBackToTop onClick={scrollToTop} />
                        <Footer theme={theme} />
                    </div>
                </div>
                {/* Modal Create Discussion */}
                <Modal
                    id="createDiscussionModal"
                    title="Buat Diskusi"
                >
                    <div className="accordion accordion-flush mb-3" id="accordionFlushExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne">
                                <button className="accordion-button bg-light collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    <div className="alert-heading text-dark"><span className="text-danger">Penting</span>, simak panduan berikut :</div>
                                </button>
                            </h2>
                            <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body bg-light">
                                    <p>Panduan ini ditujukan agar pertanyaan kamu mudah untuk dimengerti oleh member/tim Codepolitan yang ingin membantu menjawab permasalahan belajar kamu.</p>
                                    <ol type="1">
                                        <li>Gunakan bahasa yang baik dan benar</li>
                                        <li>Beri judul yang sesuai dan deskriptif</li>
                                        <li>Jelaskan masalah dengan detail</li>
                                        <li>Sediakan link screenshot error / link source code agar memudahkan orang lain dalam menjawab pertanyaanmu. Berikut beberapa sumber daya yang bisa kamu gunakan:</li>
                                        <ul>
                                            <li>Link untuk upload gambar: <a className="link text-primary" href="https://postimages.org" target="_blank" rel="noopener noreferrer">postimages.org</a></li>
                                            <li>Link untuk upload source code: <a className="link text-primary" href="https://pastebin.com" target="_blank" rel="noopener noreferrer">Pastebin</a></li>
                                        </ul>
                                    </ol>
                                </div>
                            </div>
                        </div>

                    </div>
                    <form onSubmit={handleSubmitDiscussion}>
                        <div className="mb-3">
                            <label htmlFor="questionTitle" className="form-label fw-bold">Judul Pertanyaan</label>
                            <input onChange={(e) => setThreadSubject(e.target.value)} value={threadSubject} type="text" className="form-control" id="questionTitle" required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="questionDescription" className="form-label fw-bold">Detail Pertanyaan</label>
                            <br />
                            <label className="text-muted me-2">Silahkan unggah gambar kamu di link berikut ini</label>
                            <button type="button" onClick={() => window.open('https://postimages.org', '_blank', 'location=yes,height=570,width=700,scrollbars=yes,status=yes')} className="btn btn-primary btn-sm"><FontAwesomeIcon icon={faUpload} /> Upload</button>
                            <br />
                            <label className="form-label text-muted">Setelah mengunggah gambar, salin link gambar ke pertanyaan-mu dibawah ini</label>
                            <SimpleMDE
                                value={threadContent}
                                onChange={onChangeThreadContent}
                            />
                        </div>
                        <div className="mb-3 float-end">
                            <button type="submit" className="btn btn-primary btn-lg btn-rounded" data-bs-dismiss="modal" disabled={loadingDiscussion}>Post Discussion</button>
                        </div>
                    </form>
                </Modal>

                {/* Modal Create Discussion */}
                <Modal
                    id="notesModal"
                    title={isEdit ? 'Edit Note' : 'Buat Note'}
                >
                    <form onSubmit={isEdit ? onUpdateNote : onAddNote}>
                        <div className="mb-3">
                            <label htmlFor="noteMinute" className="form-label">Menit ke</label>
                            <input onChange={(e) => setNoteMinute(e.target.value)} value={noteMinute} type="text" className="form-control" id="noteMinute" placeholder="ex. 5.30" required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="noteTitle" className="form-label">Judul</label>
                            <input onChange={(e) => setNoteTitle(e.target.value)} value={noteTitle} type="text" className="form-control" id="noteTitle" placeholder="ex. Store data in Redux" required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="noteDescription" className="form-label">Deskripsi</label>
                            <textarea onChange={(e) => setNoteDescription(e.target.value)} value={noteDescription} className="form-control" id="noteDescription" rows={3} placeholder="ex. This part describes how to storing data in Redux" required />
                        </div>
                        <div className="mb-3 float-end">
                            <button type="submit" className="btn btn-primary btn-lg btn-rounded" data-bs-dismiss="modal" disabled={loadingNotes}>{isEdit ? 'Update' : 'Submit'}</button>
                        </div>
                    </form>
                </Modal>
            </main>
        </>
    );
};

export default CourseLesson;