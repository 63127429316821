import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDate } from "../../../../utils/helper";
import { faSquarePollHorizontal } from "@fortawesome/free-solid-svg-icons";

const CardDiscussion = ({ avatar, name, subject, mark, date, courseTitle, lessonTitle, totalAnswer, theme, tags }) => {
    return (
        <div className={`card ${theme === 'dark' && 'bg-soft-dark'} mb-3 rounded-2`}>
            <div className="card-body">
                <div className={`${theme === 'dark' ? 'bg-dark' : 'bg-light'} p-3`}>
                    <div className="row">
                        <div className="col-auto">
                            <img width={50} height={50} className="rounded-circle objectfit-cover" src={avatar || "https://image.web.id/images/avatar4.jpg" || "/assets/img/placeholder-avatar.png"} alt={name || "Avatar"} />
                        </div>
                        <div className="col my-auto">
                            <h5 className={`${theme === 'dark' ? 'text-white' : 'text-muted'} fw-bold mb-0`}>{subject}</h5>
                            <p className={`${theme === 'dark' ? 'text-white' : 'text-muted'} fw-bold mb-1`}>Oleh {name}, {formatDate(date)}</p>
                            <div className="d-md-none mt-3">
                                <span className="badge bg-light border rounded-pill text-primary me-1">{totalAnswer} Jawaban</span>
                                {mark === 'solved' && (<span className="badge border rounded-pill text-primary" style={{ backgroundColor: '#DDF0EF' }}>Solved</span>)}
                            </div>
                        </div>
                        <div className="col-auto my-auto d-none d-md-inline">
                            {mark === 'solved' && (<span className="badge border rounded-pill text-primary" style={{ backgroundColor: '#DDF0EF' }}>Solved</span>)}
                        </div>
                        <div className="col-auto my-auto d-none d-md-inline">
                            {totalAnswer < 1 && (
                                <span className={`badge p-2 rounded-2 ${mark === 'solved' ? 'bg-primary' : 'bg-danger'}`}>
                                    <p className="h4 mb-0">{totalAnswer}</p>
                                    <p className="mb-0">Jawaban</p>
                                </span>
                            )}
                            {totalAnswer > 0 && (
                                <span className={`badge p-2 rounded-2 ${mark === 'solved' ? 'bg-primary' : 'bg-info'}`}>
                                    <p className="h4 mb-0">{totalAnswer}</p>
                                    <p className="mb-0">Jawaban</p>
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                {courseTitle && lessonTitle && (
                    <div className="row mt-3">
                        <div className="col">
                            <div className={`d-flex gap-2 ${theme === 'dark' ? 'bg-dark text-white' : 'text-muted bg-light'} p-2`}>
                                <FontAwesomeIcon icon={faSquarePollHorizontal} className="text-primary mt-1" />
                                <p className="m-0">
                                    Ditanyakan pada kelas <span className="text-primary">{courseTitle}</span>, topik <span className="text-primary">{lessonTitle}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                {tags && (
                    <div className="row mt-3">
                        <div className="col">
                            {tags.map((tag, index) => (
                                <span key={index} className="badge bg-secondary me-1">{tag.term_name}</span>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CardDiscussion;
