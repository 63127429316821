import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link } from "react-router-dom";
import SkeletonCardDiscussion from "../../../skeletons/SkeletonCardDiscussion/SkeletonCardDiscussion";
import CardDiscussion from "../CardDiscussion";
import { useSelector } from "react-redux";

const SectionAllDiscussion = () => {
    const { discussions, loadingDiscussion } = useSelector(state => state.discussion);
    const [limit, setLimit] = useState(10);
    const all = discussions;
    const unanswered = discussions?.filter(item => item.total_answer < 1 && item.mark !== 'solved');
    const answered = discussions?.filter(item => item.total_answer > 0 && item.mark !== 'solved');
    const solved = discussions?.filter(item => item.mark === 'solved');

    return (
        <section className="section my-5" id="allDiscussion">
            <h4 className="section-title mb-3">All Questions</h4>

            <ul className="nav nav-pills border-0 text-nowrap overflow-auto flex-nowrap my-4" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active px-3" id="pills-all-tab" data-bs-toggle="pill" data-bs-target="#pills-all" type="button" role="tab" aria-controls="pills-all" aria-selected="true">All ({all.length})</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link px-3" id="pills-unanswered-tab" data-bs-toggle="pill" data-bs-target="#pills-unanswered" type="button" role="tab" aria-controls="pills-unanswered" aria-selected="false">Unanswered ({unanswered.length})</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link px-3" id="pills-answered-tab" data-bs-toggle="pill" data-bs-target="#pills-answered" type="button" role="tab" aria-controls="pills-answered" aria-selected="false">Answered ({answered.length})</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link px-3" id="pills-solved-tab" data-bs-toggle="pill" data-bs-target="#pills-solved" type="button" role="tab" aria-controls="pills-solved" aria-selected="false">Solved ({solved.length})</button>
                </li>
            </ul>

            <div className="tab-content my-2" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab">
                    {
                        loadingDiscussion
                            ? [...Array(limit)].map((item, index) => {
                                return (
                                    <SkeletonCardDiscussion key={index} />
                                )
                            })
                            : all.map((discussion, index) => {
                                return (
                                    <Link key={index} className="link" to={`/learn/discussions/thread/${discussion.slug}`}>
                                        <CardDiscussion
                                            avatar={discussion.avatar}
                                            name={discussion.name}
                                            rank={discussion?.rank?.rank_name}
                                            job={discussion.jobs}
                                            role={discussion.role_name}
                                            subject={discussion.subject}
                                            mark={discussion.mark}
                                            date={discussion.date}
                                            courseTitle={discussion.course_title}
                                            courseSlug={discussion.course_slug}
                                            lessonTitle={discussion.lesson_title}
                                            lessonId={discussion.lesson_id}
                                            totalAnswer={discussion.total_answer}
                                            tags={discussion.tags}
                                        />
                                    </Link>
                                )

                            }).slice(0, limit)
                    }
                    {
                        all.length > limit && (
                            <div className="text-center my-3">
                                <button onClick={() => setLimit(limit + 5)} type="button" className="btn btn-outline-secondary btn-sm"><FontAwesomeIcon icon={faRedo} /> Muat lainnya</button>
                            </div>
                        )
                    }
                </div>

                <div className="tab-pane fade" id="pills-unanswered" role="tabpanel" aria-labelledby="pills-unanswered-tab">
                    {
                        loadingDiscussion
                            ? [...Array(limit)].map((item, index) => {
                                return (
                                    <SkeletonCardDiscussion key={index} />
                                )
                            })
                            : unanswered.length < 1
                                ? (<div className="text-center text-muted my-5 fw-bold">Belum ada pertanyaan</div>)
                                : unanswered.map((discussion, index) => {
                                    return (
                                        <Link key={index} className="link" to={`/learn/discussions/thread/${discussion.slug}`}>
                                            <CardDiscussion
                                                avatar={discussion.avatar}
                                                name={discussion.name}
                                                rank={discussion?.rank?.rank_name}
                                                job={discussion.jobs}
                                                role={discussion.role_name}
                                                subject={discussion.subject}
                                                mark={discussion.mark}
                                                date={discussion.date}
                                                courseTitle={discussion.course_title}
                                                courseSlug={discussion.course_slug}
                                                lessonTitle={discussion.lesson_title}
                                                lessonId={discussion.lesson_id}
                                                totalAnswer={discussion.total_answer}
                                                tags={discussion.tags}
                                            />
                                        </Link>
                                    )

                                }).slice(0, limit)
                    }
                    {
                        unanswered.length > limit && (
                            <div className="text-center my-3">
                                <button onClick={() => setLimit(limit + 5)} type="button" className="btn btn-outline-secondary btn-sm"><FontAwesomeIcon icon={faRedo} /> Muat lainnya</button>
                            </div>
                        )
                    }
                </div>

                <div className="tab-pane fade" id="pills-answered" role="tabpanel" aria-labelledby="pills-answered-tab">
                    {
                        loadingDiscussion
                            ? [...Array(limit)].map((item, index) => {
                                return (
                                    <SkeletonCardDiscussion key={index} />
                                )
                            })
                            : answered.length < 1
                                ? (<div className="text-center text-muted my-5 fw-bold">Belum ada pertanyaan</div>)
                                : answered.map((discussion, index) => {
                                    return (
                                        <Link key={index} className="link" to={`/learn/discussions/thread/${discussion.slug}`}>
                                            <CardDiscussion
                                                avatar={discussion.avatar}
                                                name={discussion.name}
                                                rank={discussion?.rank?.rank_name}
                                                job={discussion.jobs}
                                                role={discussion.role_name}
                                                subject={discussion.subject}
                                                mark={discussion.mark}
                                                date={discussion.date}
                                                courseTitle={discussion.course_title}
                                                courseSlug={discussion.course_slug}
                                                lessonTitle={discussion.lesson_title}
                                                lessonId={discussion.lesson_id}
                                                totalAnswer={discussion.total_answer}
                                                tags={discussion.tags}
                                            />
                                        </Link>
                                    )

                                }).slice(0, limit)
                    }
                    {
                        answered.length > limit && (
                            <div className="text-center my-3">
                                <button onClick={() => setLimit(limit + 5)} type="button" className="btn btn-outline-secondary btn-sm"><FontAwesomeIcon icon={faRedo} /> Muat lainnya</button>
                            </div>
                        )
                    }
                </div>

                <div className="tab-pane fade" id="pills-solved" role="tabpanel" aria-labelledby="pills-solved-tab">
                    {
                        loadingDiscussion
                            ? [...Array(limit)].map((item, index) => {
                                return (
                                    <SkeletonCardDiscussion key={index} />
                                )
                            })
                            : solved.length < 1
                                ? (<div className="text-center text-muted my-5 fw-bold">Belum ada pertanyaan</div>)
                                : solved.map((discussion, index) => {
                                    return (
                                        <Link key={index} className="link" to={`/learn/discussions/thread/${discussion.slug}`}>
                                            <CardDiscussion
                                                avatar={discussion.avatar}
                                                name={discussion.name}
                                                rank={discussion?.rank?.rank_name}
                                                job={discussion.jobs}
                                                role={discussion.role_name}
                                                subject={discussion.subject}
                                                mark={discussion.mark}
                                                date={discussion.date}
                                                courseTitle={discussion.course_title}
                                                courseSlug={discussion.course_slug}
                                                lessonTitle={discussion.lesson_title}
                                                lessonId={discussion.lesson_id}
                                                totalAnswer={discussion.total_answer}
                                                tags={discussion.tags}
                                            />
                                        </Link>
                                    )

                                }).slice(0, limit)
                    }
                    {
                        solved.length > limit && (
                            <div className="text-center my-3">
                                <button onClick={() => setLimit(limit + 5)} type="button" className="btn btn-outline-secondary btn-sm"><FontAwesomeIcon icon={faRedo} /> Muat lainnya</button>
                            </div>
                        )
                    }
                </div>
            </div>
        </section >
    );
};

export default SectionAllDiscussion;
